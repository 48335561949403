import React, { useState, useEffect } from 'react';
import profilepic from '../images/foto_azul.jpeg';
import pic3 from '../images/foto3.jpg';
import pic5 from '../images/foto5.jpg';

function Info() {
    const images = [profilepic, pic3, pic5];
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const [blur, setBlur] = useState(false);

  useEffect(() => {
    const timer = setInterval(() => {
      setBlur(true);
      setTimeout(() => {
        setCurrentImageIndex((currentImageIndex + 1) % images.length);
        setBlur(false);
      }, 500); // Half the duration of the transition effect
    }, 2500); // Change image every 3 seconds
    return () => clearInterval(timer);
  }, [currentImageIndex, images.length]);

    return (
        <div>
            <br></br>
            <div className = "centered-container-info">
            <img src={images[currentImageIndex]} width={200} className={`info--picture ${blur ? 'blur' : ''}`} />
                <h5 className="name">Gabriel Sinay</h5>
                <p className = "role">Full Stack Developer</p>
                <div className="button-container">
                    <button className="button btn btn-primary"><a className = "tag-main" href= "https://linkedin.com/in/gsinay">LinkedIn</a></button>
                    <button className="button btn btn-light"><a className = "tag-secondary" href = "https://github.com/gsinay">GitHub</a></button>
                </div>
            </ div>

            <div className = "Justified-container-info">
                <h3>About</h3>
                <p> What do you get when you fuse a video game enthusiast 🎮, foodie 🍣, kiteboarder 🏄 and a musician 🎸? A (mostly) self taught Software Engineer 👨‍💻 with a high
                    drive to build things that people want (Thanks for the quote, <a href='http://www.paulgraham.com'>Paul</a>). As a lover of all things STEM, arts/design and people-oriented,
                    I believe that my "holistic" approach to work, education and passions is an invaluable asset for tackling big problems with even bigger solutions.
                    I'm currently looking for internships / part-time job opportunities in the field, or anything loosely related! Like what you see? Shoot me an email, you miss 100% of the
                    shots you do not take ;).   </p>
                <h3>Education</h3>
                <ul>
                    <li>International School Nido de Aguilas. 3.94 GPA</li>
                    <li>Currently: Industrial and SWE Engineering with a title in Informations Technology @ Pontificia Universidad Católica de Chile, top 10% of entering class, 6.1/7 GPA</li>
                </ul>
                <h3>Skills</h3>
                <ul>
                    <li>Python 3.X (Framework(s): Django, FastAPI)</li>
                    <li>Ruby (Framework(s): Rails)</li>
                    <li>C</li>
                    <li>JS: NodeJs (Express, Koa) on the backend, React on the frontend</li>
                    <li>PostgreSQL</li>
                    <li>Currently learning AWS</li>
                </ul>

                <h3>Work / voluntary experience</h3>
                <ul>
                    <li>5th grade group leader: Bet-el Chile Non formal Education. Organized and executed
                        weekly activites for a group of 15 5th graders centred on jewish morals and values.
                    </li>
                    <li>Chief of staff: Bet-el Chile Non formal Education. Oversaw compliance of a group of 20 leaders.
                        Organized, planned, financed and executed weekly meetings between leaders, monthly meetings between executives, 
                        montly team-building activities such as seminars, and oversaw the execution of a 3 day camp and 10 day camp for 60 kids. 
                    </li>
                    <li>Overall head of education and leader relationships: Bet-el Chile Non formal Education. Overall
                        Edecutive in charge of the judaic content of the organization, and the relationship between leaders and executives.
                        Tasks included direct communication with the financing committee, overseeing the execution of organization wide events (such as
                        trips for leaders, financing, organization and production of a gala for 200 + people, etc.) and the creation of a new learning curriculum. 
                    </li>
                    <li>Semestral project for course IIC2143 Software Engineering: <a href='https://dripswap.onrender.com'>Dripswap</a>, a web app for Shoe exchanging. Written in the
                    Ruby on Rails framework and vanilla CSS(Designing is not my forte, though :( )</li>
                </ul>
                <p className='lenguaje'>&lt;&lt;&lt;Disponible en Español&gt;&gt;&gt;</p>
            
            </div>

        </div>
        

    )
}

export default Info;
