
import Info from "./components/Info";
import "./style.css";

function App() {
    return(
        <div className="container">
            <Info />
        </div>
    )
}

export default App;